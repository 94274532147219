import React from "react";
import GetOutlook from "./GetOutlook";

import Calendly1 from "./Calendy";
import "../styles/BookanApp.scss";
import video from "../assets/Images/video.png";
import clock from "../assets/Images/clock.png";

const BookAnAppointment = () => {
  return (
    // <div className='BookAnAppointment container'>
    //     <div className='row bookclass'>
    //         <div className='col-md-6 text-container card'>
    //             <div className="card-header">
    //                 <h1>Booking An Appointment</h1>
    //             </div>
    //             <div className="card-body">
    //                 <h3>Introduction call</h3>
    //                 <p><img src={clock}></img><span>30 min</span></p>
    //                 <p><img src={video}></img><span>Web conferencing details provided upon confirmation</span></p>
    //             </div>
    //         </div>
    //         <div className='col-md-6 Cal-wrapper'>
    //             <div className='card'>
    //                 <div className='card-header'>
    //                     <h4>Select a Date & Time</h4>
    //                 </div>
    //                 <div className='card-body'>
    //                     <Calendly1 />
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    // </div>
    <div className="BookAnAppointment container">
      <div className="row bookclass">
        <div className="col-12 text-container card">
          <div className="card-header">
            <h1>Booking An Appointment</h1>
          </div>
          {/* <div className="card-body">
                        <h3>Introduction call</h3>
                        <p><img src={clock}></img><span>30 min</span></p>
                        <p><img src={video}></img><span>Web conferencing details provided upon confirmation</span></p>
                    </div> */}
        </div>
      </div>
      <div className="row bookclass">
        <div className="col-12 Cal-wrapper">
          <div className="card">
            {/* <div className='card-header'>
                            <h4>Select a Date & Time</h4>
                        </div> */}
            <div className="card-body">
              <Calendly1 />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookAnAppointment;
